import React from 'react';

import './Section.css';

const Section = ({ children, style, constrainWidth = true }) => (
  <section style={style}>
    {constrainWidth ? (
      <div className="SectionDiv">
        {children}
      </div>
    ) : children}
  </section>
);

export default Section;
