import React from 'react'

import './Grid.css'

const Grid = ({ children, style }) => (
  <div className="Grid" style={style}>
    {children}
  </div>
)

export default Grid
