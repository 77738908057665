import React from "react"
import { graphql } from "gatsby"
// import Image from "gatsby-image"

import Grid from "../components/Grid/Grid"
import Section from "../components/Section/Section"
import Seo from "../components/Seo/Seo"
import { GatsbyImage } from "gatsby-plugin-image"

const IndexPage = ({
  data: {
    logoImage: {
      childImageSharp: {
        gatsbyImageData: logoImage,
      },
    },
    coverImage: {
      childImageSharp: {
        gatsbyImageData: coverImage,
      },
    },
    markImage: {
      childImageSharp: {
        gatsbyImageData: markImage,
      },
    },
  },
}) => (
  <>
    <Seo />
    <Section style={{}}>
      <h1>
        <GatsbyImage
          image={logoImage}
          alt="Magical Trash"
          style={{
            maxWidth: "200px",
            margin: "0 auto 40px",
          }}
        />
      </h1>
      <GatsbyImage
        image={coverImage}
        alt="Magical Trash – Man of Anxiety"
        style={{
          marginBottom: "40px",
        }}
      />
      <h1>Magical Trash</h1>
      <h4>Presents</h4>
      <h2>Man of <em>Anxiety</em></h2>
      <p style={{ marginBottom: "40px" }}>
        Using samples from childhood recordings and CCM hits as a starting point, Man of Anxiety is an exploration in looking backward in order to find peace in the present. Examining the connections between being a new father and lived paternal relationships and the overbearing presence of patriarchy in all facets of life. The sound can be described as "post-new wave", pulling from influences like OMD, Severed Heads, and Human League to create off-kilter but richly textured electronic pop songs.
      </p>
      <h3>Tracklist</h3>
      <ol style={{ marginBottom: "40px" }}>
        <li>Amphora</li>
        <li>Against Authority</li>
        <li>I Wish For a Light</li>
        <li>Future Child</li>
        <li>Man of Anxiety</li>
      </ol>
      <a className="button" href="//magicaltrash.bandcamp.com">Preorder on Bandcamp</a>
    </Section>
    <Section style={{
      background: "var(--color-safety-yellow)",
      color: "var(--color-background)",
    }}>
      <h2>About</h2>
      <Grid style={{ flexDirection: "row-reverse" }}>
        <p>
          Magical Trash is the solo project of Mark E. Carter. He has been writing,
          recording, and playing music since his teenage years. This project initially
          started as a challenge to write and record an EP for every month of the year
          in 2016. Since then Magical Trash continues to be a creative outlet for
          fusing synth and computer-based composition and experiments with post-punk
          guitar, drum, and vocal aesthetics.
        </p>
        <GatsbyImage
          alt="Photo of Mark"
          image={markImage}
        />
      </Grid>
    </Section>
    <Section style={{
      background: "var(--color-text)",
      color: "var(--color-background)",
    }}>
      <h2><span>E</span>lsewhere</h2>
      <ul style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        <li><a style={{ margin: "0 20px 20px" }} className="button" href="//magicaltrash.bandcamp.com">Bandcamp</a></li>
        <li><a style={{ margin: "0 20px 20px" }} className="button" href="//twitter.com/magikaltrash">Twitter</a></li>
      </ul>
    </Section>
    <Section>
      <p style={{
        textAlign: "center",
        color: "var(--color-secondary)",
        fontSize: "0.75em",
        textTransform: "uppercase",
      }}>©2022 Mark E. Carter / Magical Trash</p>
    </Section>
  </>
)

export const query = graphql`
  query IndexQuery {
    logoImage: file(relativePath: { eq: "Logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 360, quality: 80)
      }
    }
    coverImage: file(relativePath: { eq: "ManOfAnxiety.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100)
      }
    }
    markImage: file(relativePath: { eq: "MarkWithBaby.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 80)
      }
    }
  }
`;

export default IndexPage
